<template>
    <div :class="closable ? 'library-document' : 'library-document full'">
        <img v-if='closable' @click='$emit("close")' class='close' src="@/assets/images/icons/close-black.svg" alt="Fermer">
        <h2>{{doc.title}}</h2>
        <!-- <object :data="'https://docs.google.com/gview?embedded=true&url='+doc.src" type="">
        <embed :src="'https://docs.google.com/gview?embedded=true&url='+doc.src" type="">

        </object>
        <PDFReader :src="doc.src" /> -->
        <div ref="embed" id="embed"></div>
    </div>
</template>
<script>
// import pdf from 'pdfvuer'
import PDFReader from './PDFReader/Document'
export default {
  components:{
    PDFReader
  },
    props:{
      doc: {
        type:Object
      },
      closable:{
        type:Boolean,
        default:true
      }
    },
    mounted() {
      if (!window.adobe_dc_sdk) {
        const script =  document.createElement('script')
        script.src = 'https://documentcloud.adobe.com/view-sdk/main.js'
        document.body.append(script)
        document.addEventListener("adobe_dc_view_sdk.ready", this.handlePDF)
      } else {
        this.handlePDF()
      }

      // PDFObject.embed('https://docs.google.com/gview?embedded=true&url='+this.doc.src, '.embed')
    },
    methods:{
      handlePDF(){
         var adobeDCView = new window.AdobeDC.View({clientId: "7c7b91d6f405420a8d5f34a434810121", divId: "embed"});
        adobeDCView.previewFile(
       {
          content:   {location: {url: this.doc.src}},
          metaData: {fileName:this.doc.title}
       },
        {defaultViewMode: "FIT_WIDTH", showAnnotationTools: false, showLeftHandPanel: false,
			 showPrintPDF: false});
      }
    },
}
</script>
<style lang="scss">

  .embed {
    width: 100%;
    height: 100%;
  }
</style>
