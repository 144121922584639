<template>
  <div class="pdf_reader" ref="main"></div>
</template>
<script>
import 'pdfjs-dist/build/pdf.worker.entry'
var pdfjsLib = require('pdfjs-dist/build/pdf')
export default {
  props: ['src'],
  data() {
    return {
      pdf: undefined,
      pages: []
    }
  },
  created() {},
  mounted() {
    let self = this;
    var loadingTask = pdfjsLib.getDocument(this.src)
    loadingTask.promise.then(function(pdf) {
      const pages = pdf._pdfInfo.numPages
      let images_pages = []

      for (let i = 1; i < pages + 1; i++) {
        pdf.getPage(i).then(function(page) {
          // conteneur de la page
          let pageContainer = document.createElement('div')
          pageContainer.classList.add('pageContainer')
          // canvas où est dessiné la page
          let canvas = document.createElement('canvas')
          const ctx = canvas.getContext('2d')
          // conteneur du textLayer
          let textLayerContainer = document.createElement('div');
          textLayerContainer.classList.add('textLayer')

          const viewport = page.getViewport({ scale: 1 })
          const renderContext = { canvasContext: ctx, viewport: viewport }
          canvas.height = viewport.height
          canvas.width = viewport.width
          page.render(renderContext)

          page.getTextContent().then(textContent => {
            var textLayer = pdfjsLib.renderTextLayer({
              textContent: textContent,
              container:textLayerContainer,
              viewport: viewport
            })
            textLayer._render()
            pageContainer.append(canvas)
            pageContainer.append(textLayerContainer)
            self.$refs['main'].append(pageContainer)
          })
        })

        //textLayer.setTextContent(textContent);
        //textLayer.render();
      }
    })
  }
}
</script>
<style lang="scss">
.pdf_reader {
  overflow-y: scroll;
}
canvas {
}

.pdf_reader {
  .pageContainer {
    position:relative;
    .textLayer {
    position: absolute;
    top:0;
    span {
      position: absolute;
      font-size: 10px;
      font-family: Arial !important;
      margin-left: 1%;
    }
  }
  }
}
</style>
